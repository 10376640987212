<script>
import moment from 'moment'
var md5 = require('md5');

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      screen_mode: "",
      swalVisible: false,
    }
  },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    md5(val) {
      return md5(val);
    },
    redirect(path, obj = null) {
      if (this.$route.path !== path) {
        if (obj) {
          this.$router.push({ path: path, query: obj });
        } else {
          this.$router.push(path)
        }
      }
    },
    removeQueryString() {
      const newUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, newUrl);
    },
    set_session_partner(data) {
      let acc = {
        id: data.id,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        token: data.token
      }
      localStorage.setItem("tnjtek_partner", JSON.stringify(acc));
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay', `Logout`], dangerMode: true })
        .then((ok) => {
          if (ok) {
            this.logout();
          }
        });
    },
    logout() {
      localStorage.setItem("tnjtek_partner", JSON.stringify(new Object));
      setTimeout(() => window.location.href = '/', 1);
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    convertTZ(date, tzString) {
      return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })).toISOString().split('T')[0];
    },
    ExpirationColor(inputDate) {
      const inputDateObj = new Date(inputDate);
      const currentMoment = new Date();
      const sevenDaysLater = new Date();
      sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);

      if (inputDateObj <= currentMoment) {
        return "danger";
      } else if (inputDateObj <= sevenDaysLater) {
        return "warning";
      } else {
        return "success";
      }
    }
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm A');
    },
    date_format: function (date) {
      return moment(date).format('D MMM, YYYY - HH:mm');
    },
    date_short: function (date) {
      return moment(date).format('D MMM, YYYY');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm A') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm A') + " (PST)";
    },
    date_qr_updated: function (date) {
      return moment(date).subtract(7, 'hours').format('D-MM-YYYY HH:mm:ss');
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    date_expiration: function (date) {
      var inputDate = moment(date).startOf('day');
      var today = moment().startOf('day');
      var daysDiff = inputDate.diff(today, 'days');

      if (daysDiff === 0) {
        return 'Today';
      } else if (daysDiff === 1) {
        return 'Tomorrow';
      } else if (daysDiff > 1) {
        return 'In ' + (daysDiff + 1) + ' days';
      } else {
        return 'Expired';
      }
    },
    formatNumber(number) {
      // Convert number to string and split it into an array of characters
      let numberString = number.toString().split('');

      // Insert commas at appropriate positions
      for (let i = numberString.length - 3; i > 0; i -= 3) {
        numberString.splice(i, 0, ',');
      }

      // Join the array back into a string and return
      return numberString.join('');
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  },
};
</script>
