import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("LoginPage")
  },
  {
    path: '/set_password',
    name: 'SetPassword',
    component: lazyLoad("SetPassword")
  },
  {
    path: '/partner',
    component: lazyLoad("Partner/Base"),
    children: [
      {
        path: '',
        name: 'PartnerAccount',
        component: lazyLoad("Partner/Account")
      },
      {
        path: 'refund',
        name: 'PartnerRefund',
        component: lazyLoad("Partner/Refund")
      },
      {
        path: 'information',
        name: 'PartnerInformation',
        component: lazyLoad("Partner/Information")
      },
      {
        path: 'notification',
        name: 'PartnerNotification',
        component: lazyLoad("Partner/Notification")
      },
      {
        path: 'withdraw',
        name: 'PartnerWithdraw',
        component: lazyLoad("Partner/Withdraw")
      },
      {
        path: 'export_transaction',
        name: 'PartnerExportTransaction',
        component: lazyLoad("Partner/ExportTransaction")
      },
      // .............................................
      {
        path: 'member/:id',
        name: 'PartnerMember',
        component: lazyLoad("Partner/Member"),
        props: true,
      },
      {
        path: 'station/:id',
        name: 'PartnerStation',
        component: lazyLoad("Partner/Station"),
        props: true,
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
